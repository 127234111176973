// var data = require("../javascripts/json/projets.json");

// const dataString = document.querySelectorAll("[data-key]");

// var contentEl = document.querySelector(".content-2").children;

// var ct3 = document.querySelector(".content-2");
// var buttonInfo = document.querySelector(".button-info");
// var info = document.querySelector(".info");
// var imgOpen = document.querySelector(".img-open");

// for (let i = 0; i < 4; i++) {
//   contentEl[i].addEventListener("click", (e) => {
//     ct3.classList.add("blur");
//     info.classList.remove("display");
//     dataString.forEach((element) => {
//       let value = element.getAttribute("data-key");
//       element.innerHTML = data[i][value];
//       imgOpen.setAttribute("src", data[i].image);
//     });
//   });
// }

// buttonInfo.addEventListener("click", (e) => {
//   ct3.classList.remove("blur");
//   info.classList.add("display");
// });
